<template>
  <b-card class="h-100 card-auto-search">
    <b-row class="match-height m-0 h-100">
      <div class="p-0 d-flex justify-content-between align-items-center w-100 flex-wrap">
        <div class="text-information mr-1">{{ $t('dashboard.analyzeInfluencer') }}</div>
        <b-button class="button-max-views d-block" @click="handleClickButtonMaxViews">
          <span :class="{'info-max-views': max_view <= 5 && is_brand_premium}">{{ $t('maxviews')}} {{ separatebycomma(max_view) }}</span>
          <span class="buy-more-info" v-if="max_view <= 5 && is_brand_premium">{{ $t('getMore') }}</span>
        </b-button>
      </div>
      <b-col
        md="12"
        class="mt-1 p-0"
      >
      <b-tabs :active-nav-item-class="`colorTab${networkTabColor}`" active-tab-class="border-0" v-model="active_tab">
        <b-tab
          v-on:click="selectNetwork('instagram')"
        >
          <template #title>
            <feather-icon icon="InstagramIcon" size="20"/>
            Instagram
          </template>
        </b-tab>
        <b-tab
          v-on:click="selectNetwork('tiktok')"
        >
          <template #title>
            <span :class="`icomoon-tiktok tiktokIconAnalize icon-tiktok`" v-if="active_tab === 1"></span>
            <span :class="`icomoon-tiktok tiktokIconAnalize`" v-else></span>

            TikTok
          </template>          
        </b-tab>
        <b-tab
          v-on:click="selectNetwork('twitter')"
        >
          <template #title>
            <b-img src="@/assets/images/icons/twitter-x-black.svg" class="network-x-analice"/> 
            Twitter
          </template>
        </b-tab>
        <b-tab
          v-on:click="selectNetwork('youtube')"
        >
          <template #title>
            <feather-icon icon="YoutubeIcon" size="20"/>
            Youtube
          </template>
        </b-tab>
        <b-tab
          v-on:click="selectNetwork('twitch')"
        >
          <template #title>
            <feather-icon icon="TwitchIcon" size="20"/>
            Twitch
          </template>        
        </b-tab>
        </b-tabs>
      </b-col>
      <div
        class="block d-flex w-100"
      >
        <b-row class="w-100 px-1">
          <b-col class="col-12 p-0">
            <div class="mb-05 d-flex justify-content-between flex-wrap">
              <span class="d-block mb-1 mb-sm-0"> {{$t('maxviews') }} {{max_view}} {{ $root.change_max_views }} </span>
              <span class="text-blue text-underline-no-credits"  v-if="max_view <= 5 && is_brand_premium" @click="handleClickButtonMaxViews">{{ $t('noCreditsBuy') }}</span>
            </div>
            <b-form-input
              v-model="query"
              @focus="show_suggestions = true; update_suggestions = !update_suggestions"
              @blur="blurActionInput"
              autocomplete="off"
            >
            </b-form-input>

            <suggestions-container-auto-search
              :show_suggestions="show_suggestions"
              :key="update_suggestions"
              :data="data_list"
              :query="query"
              :loader="loader"
              :networkSearch="networkSearch"
              @open_modal_membership="open_modal_plans_group = true; update_modal = ! update_modal"
            />
          </b-col>
        </b-row>
      </div>
    </b-row>
    <modal-membership-group
      :open_modal_plans_group="open_modal_plans_group" 
      :key="update_modal"
      :month_dad="true"
      :texts="{title: $t('creator.msjTitlePremiumBrand'), subtitle: $t('creator.subtitlePremiumBrand')}"
    />
  </b-card>
</template>

<script>
import {
  BRow, BCol, BCard, BTab, BTabs, BImg, BFormInput, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { hasPermission, getUserData } from '@/libs/utils/user';
import { trimText, separatebycomma } from '@/libs/utils/formats';
import axios from 'axios';
import service_profile from '@/services/others'
import config from '@/services/config'

export default {
  components: {
    BImg,
    BRow,
    BCol,
    BCard,
    BTab,
    BTabs,
    BFormInput,
    BButton,
    suggestionsContainerAutoSearch: () => import('./suggestionsContainerAutoSearch.vue'),
    ModalMembershipGroup: () => import('@/views/components/modal/ModalMembershipGroup.vue'),
  },
  directives: {
    Ripple
  },
  props: {
    active:{
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      separatebycomma,
      show_suggestions: false,
      update_suggestions: false,
      cancel_ws: null,
      datasuggest: [],
      query: '',
      filteredOptions: [],
      inputProps: {
        id: 'autosuggest__input',
        class: 'form-control',
        placeholder: ''
      },
      networkSearch: 'instagram',
      networkName: 'Instagram',
      limit: 9,

      moreInfo: {
        avatar_url: require('@/assets/images/icons/compass.svg'),
        type: 'empty',
        title:  this.$t('dashboard.info'),
        username: '',
        is_verified: false,
        class: 'moreInfo'
      },
      finish: true,
      loader: false,
      text: this.$t('dashboard.analyze'),
      textResults: this.$t('dashboard.top'),
      ops: {
        vuescroll: {},
        scrollPanel: {
          maxHeight: 130
        },
        rail: {},
        bar: {
          background: '#0077e9'
        }
      },
      max_view: 0,
      networkTabColor: 'instagram',
      active_tab: 0,
      data_list: [],
      open_modal_plans_group: false,
      update_modal: false,
      typing_timer: null,
    }
  },
  computed: {
    is_brand_premium() {
      return getUserData() && getUserData().main_group.name !== 'creator' && getUserData().current_plan.name !== 'Free'
    }
  },
  created () {
    this.inputProps.placeholder = `${this.$t('dashboard.nameuser')} ${this.networkName}`
    this.filteredOptions = this.topIG;
    this.finish = !this.checkAnalyzer();
    this.data_list = [this.moreInfo]
    this.getMaxViews();
  },
  methods: {
    blurActionInput() {
      setTimeout(() => {        
        this.show_suggestions = false;
        this.update_suggestions = !this.update_suggestions;
      }, 200);
    },
    network_name(network) {
      
      if (network === 'instagram')
        return 'Instagram'
      if (network === 'tiktok')
        return 'TikTok'
      if (network === 'youtube')
        return 'YouTube'
      if (network === 'twitch')
        return 'Twitch'
      if (network === 'twitter')
        return 'Twitter'
      return network
    },
    selectNetwork (red) {
      this.networkSearch = red
      this.networkName = this.network_name(red)
      this.inputProps.placeholder = `${this.$t('dashboard.nameuser')} ${this.networkName}`
      this.filteredOptions = red === 'youtube' ? this.topYT : red === 'tiktok' ? this.topTT : red === 'twitch' ? this.topTW : red === 'twitter' ? this.topTR : this.topIG
      this.ops.scrollPanel.maxHeight = 130
      this.resetQuery()
    },
    handleClickButtonMaxViews() {
      if (this.is_brand_premium && this.max_view <= 5) {
        this.$router.push({ name: 'pages-account-setting', params: { section: 'membership', tab: 'packages' } }).catch(() => {})
      }
    },
    resetQuery () {
      this.query = ''
      this.inputProps.class = 'form-control'
    },
    getResultsType() {
      this.loader = true
      const CancelToken = axios.CancelToken;
      if (this.cancel_ws) this.cancel_ws()
      const self = this;
      axios({
        method: 'post',
        url: `${config.urlCore}/api/v1/finder/suggester/`,
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
        data: {
          query: trimText(this.query),
          networks: this.networkSearch
        },
        cancelToken: new CancelToken(function executor(c) {
          self.cancel_ws = c;
        })
      }).then((response) => {
        this.data_list = response.data.response.list.length > 0 ? response.data.response.list : [this.moreInfo]
        this.loader = false;
      }).catch(error => {
        if (!axios.isCancel(error)) {
          this.data_list = [this.moreInfo]
          this.loader = false;
        }
      });
    },
    getMaxViews() {
      service_profile.getMaxViews().then(response => {
        this.max_view = response.max_views;
      })
    },
    searchInfluencer () {
      this.getResults()
      this.filteredOptions = []
    },
    checkAnalyzer() {
      return hasPermission('view_analyzer');
    },
  },
  watch: {
    '$i18n.locale'() {
      this.inputProps.placeholder = `${this.$t('dashboard.nameuser')} ${this.networkName}`
    },
    active_tab(val) {
      this.data_list = [this.moreInfo]
      if (val === 0) {
        this.networkTabColor = 'instagram'
      } else if (val === 1) {
        this.networkTabColor = 'tiktok'
      } else if (val === 2) {
        this.networkTabColor = 'twitter'
      } else if (val === 3) {
        this.networkTabColor = 'youtube'
      } else if (val === 4) {
        this.networkTabColor = 'twitch'
      }
      this.$emit('changeNetwork', this.networkTabColor)
    },
    query(value) {
      if (value.length > 0) {
        if (this.typing_timer) {
          clearTimeout(this.typing_timer);
        }
        this.typing_timer = setTimeout(this.getResultsType, 500);
      }
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-autosuggest.scss';
</style>

<style scoped lang="scss">
  .button-max-views {
    background: linear-gradient(to left bottom, rgb(124, 214, 175), rgb(62, 123, 160));
    color: white;
    border: #9449fa;
    height: 40px;
    position: relative;
    overflow: hidden;
    &:hover {
      .buy-more-info {
        transition: all 300ms;
        transform: translateY(0em);
      }
      .info-max-views {
        opacity: 0;
      }
    }
    &:not(:hover) {
      .buy-more-info {
        transition: all 300ms;
      }
    }
    .buy-more-info {
      background-image: linear-gradient(to right, #667eea, #8E37D7);
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      transform: translateY(-3em);
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .text-underline-no-credits {
    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
  .mb-05 {
    margin-bottom: 0.5em;
  }
  .network-x-analice {
    width: 1.2em !important;
    margin-right: 6px;
  }
  .radio label {
    position: relative;
    cursor: pointer;
    float: left;
    line-height: 40px;
    margin-bottom:0 !important;
  }
  .radio input {
    position: absolute;
    opacity: 0;
  }
  .radio input:checked ~ .radio-control {
    border-bottom: 1px solid red;
    color: red;
    margin-bottom:4px;
  }

  .instagramBoton {
    background: red;
  }
  .text-information {
    font-size: calc(1.2890625rem + 0.46875vw);
    font-weight: bold;
    line-height: 1.2;
  }
</style>

<style>
  .card-auto-search {
    border-radius: 1.5em;
  }
  .container-loader {
    height: 100%;
    width: 100%;
    position: absolute;
    background-color: rgba(255,255,255, 0.5);
    z-index: 1000;
    right: 5px;
  }
  .loader-table {
    top: 0px;
    z-index: 10;
    display: flex;
    justify-content: center;
  }
  .spinner-table-searcher {
    margin-top: 3em;
    height: 3em;
    width: 3em;
    color: #acacac;
  }
  .colorTabinstagram {
    color: #ed4960 !important;
    border-bottom: 2px solid #ed4960 !important;
  }
  .colorTabyoutube {
    color: #ff051d !important;
    border-bottom: 2px solid #ff051d !important;
  }
  .colorTabtiktok {
    color: #ff0653 !important;
    border-bottom: 2px solid #ff0653 !important;
  }
  .colorTabtwitch {
    color: #9449fa !important;
    border-bottom: 2px solid #9449fa !important;
  }
  .colorTabtwitter {
    color: black !important;
    border-bottom: 2px solid black !important;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  }
  .nav-tabs .nav-link:after {
    background: none !important;
  }
  .tiktokIconAnalize {
    font-size: 1.2em;
    margin-right: 0.4em;
    color: #444 !important;
  }
  .moreInfo h5 {
    color: #2577d4;
  }
  .moreInfo h5:hover {
    color: #0067cc;
  }
  .buttonAnalyze {
    background-color:#0096fe !important;
    border: 0 !important;
  }
  .buttonAnalyze:hover{
    background-color:rgb(77, 182, 249) !important;     
    box-shadow: rgba(77, 182, 249, 0.3) 0px 7px 29px 0px !important;  
  }
  .buttonAnalyze:disabled {
    background-color: #0094fe86 !important;
  }
  .buttonAnalyze:disabled:hover {
    box-shadow: none !important;
  }
  .icon-tiktok{
    color: #ff0653 !important;
  }
</style>
